import React from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '../../../atoms/TextField';
import { CITIES_QUERY } from '../../../../apollo/queries';
import {useTranslation} from '../../../../config/i18n'

const CitiesAutocomplete = ({
  defaultValue,
  onChange,
  id,
  variant,
  label,
  getOptionLabel,
  inputProps,
  error,
  helperText,
  noOptionsText,
  ...props
}) => {
  const [cityInput, setCityInput] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([{ ...defaultValue }]);
  const { t } = useTranslation(['common']);

  const handleCompleteFetchCities = citiesData => {
    if (citiesData && citiesData.filterCities && citiesData.filterCities[0]) {
      setOptions(citiesData.filterCities ? [...citiesData.filterCities] : []);
    }
  };

  const [fetchCities, { loading: citiesLoading }] = useLazyQuery(CITIES_QUERY, {
    variables: { query: cityInput },
    onCompleted: handleCompleteFetchCities,
  });

  //   const loading = open && citiesLoading;

  // React.useEffect(() => {
  //   if (citiesData && citiesData.filterCities) {
  //     setOptions([...citiesData.filterCities]);
  //   }
  // }, [citiesData]);



  React.useEffect(() => {
    if (cityInput) {
      fetchCities();
    }
  }, [cityInput]);

  return (
    <Autocomplete
      onChange={onChange}
      id={id}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, val) => option.name === val.name}
      getOptionLabel={(option) =>
        option.country ? `${option.name}, ${option.country.name}` : option.name
      }
      noOptionsText={t('common:search_location')}
      // includeInputInList
      filterSelectedOptions
      options={options}
      loading={citiesLoading}
      defaultValue={defaultValue}
      renderInput={(params) => (
        <TextField
          {...inputProps}
          error={error}
          helperText={helperText}
          {...params}
          label={label}
          fullWidth
          variant={variant}
          onChange={(e) => setCityInput(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {citiesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      // renderOption={(option) => {
      //   const optionElement =
      //     option.country && option.country.name ? (
      //       <div>
      //         <strong>{option.name}</strong>, {option.country.name}
      //       </div>
      //     ) : (
      //       <div>{option.name}</div>
      //     );
      //   return optionElement;
      // }}
      {...props}
    />
  );
};

CitiesAutocomplete.propTypes = {
  onChange: PropTypes.func,
};

CitiesAutocomplete.defaultProps = {
  onChange: () => {},
};

export default CitiesAutocomplete;
