import React from 'react'
// import PropTypes from 'prop-types'
import { Menu as MuiMenu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem'


const Menu = ({...props}) => {
    return (
        <MuiMenu  {...props} />
    )
}

Menu.propTypes = {

}

Menu.Item = MenuItem;

export default Menu
