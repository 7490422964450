import React from 'react';
import PropTypes from 'prop-types';
import { StyledTypography, StyledLink } from './style';
import { urlCheckRegex } from '../../../helpers';




const Typography = ({ detectUrls, maxLines, color, breakWord, weight, variant, onClick, display, children, margin, ...props }) => {
  let  parsedChildren = children;
  if (detectUrls && typeof children === 'string') {
    const parts = children.split(urlCheckRegex);
    for (let i = 1; i < parts.length; i += 2) {
      parts[i] = <StyledLink key={i} href={parts[i]} target="_blank" rel="noreferrer">{parts[i]}</StyledLink>
    }
    parsedChildren = parts;
  }

  return (
    <StyledTypography
      weight={weight}
      variant={variant}
      maxlines={maxLines}
      onClick={onClick}
      breakWord={breakWord}
      display={display}
      color={color}
      margin= {margin}
      {...props}
    >
      {parsedChildren}
    </StyledTypography>
  );
};

Typography.propTypes = {
  maxlines: PropTypes.number,
  weight: PropTypes.string,
  display: PropTypes.string,
  variant: PropTypes.string.isRequired,
  breakWord: PropTypes.bool,
  detectUrls: PropTypes.bool,
  color: PropTypes.string,
  margin: PropTypes.string
};

Typography.defaultProps = {
  maxlines: undefined,
  weight: 'auto',
  display: undefined,
  breakWord: false,
  detectUrls: false,
  color: '',
  margin: null
};

export default Typography;
