/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Close } from '@mui/icons-material';
import Autocomplete from '@app/components/molecules/Autocomplete';
import TextField from '@app/components/atoms/TextField';
import IconButton from '@mui/material/IconButton';
import RoomIcon from '@mui/icons-material/Room';
import LanguageIcon from '@mui/icons-material/Language';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import PublicIcon from '@mui/icons-material/Public';
import DateRangeIcon from '@mui/icons-material/DateRange';
import Image from 'next/image';
import { gql , useMutation, useQuery } from '@apollo/client';
// import { CardActions, CardMedia } from '@material-ui/core';
// import Chip from '@material-ui/core/Chip';
import Skeleton from '@mui/lab/Skeleton';
import { ALL_OPPORTUNITIES, MY_SPACES , PREMIUM_USER_QUERY } from '@app/apollo/queries';
import { CREATE_SPACE, CLONE_OPPORTUNITY, DELETE_OPPORTUNITY } from '@app/apollo/mutations';
// import AccessAlarmRoundedIcon from '@material-ui/icons/AccessAlarmRounded';
import {
  CardActionArea,
  Tooltip,
  Box,
  Zoom,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // CardMedia
} from '@mui/material';
import Button from '@atoms/Button';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Typography from '@atoms/Typography';
import Menu from '@molecules/Menu';
import Link from '@atoms/Link';
import { USER_ROLES } from '@app/config/constants';
import MutationsStatus from '@app/components/molecules/MutationsStatus';
import { OPP_STATUS_ENUM } from '@app/constants';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { useTranslation } from '../../../../config/i18n';
import { redirectToRoute } from '../../../../helpers';
import {
  CardContent,
  ContentContainer,
  CardHeader,
  Card,
  CardLabel,
  useClasses,
  OrganisationAvatar,
  MetaItem,
  OpportunityDescription,
  StyledLink,
  CardSublabel,
} from './style';

const OpportunityCard = ({
  id,
  slug,
  title,
  // city,
  location,
  is_draft,
  opp_status,
  logo,
  deadline_date,
  organisation,
  start_date,
  is_virtual,
  is_worldwide,
  end_date,
  description,
  is_paid,
  space,
  // genres,
  summary,
  types,
  user,
  userInfo,
  onClick,
  refetch,
  // responsive_set,
  // placeholder,
  oppSearch,
  loading,
}) => {
  const { t } = useTranslation(['dashboard', 'applications', 'wizard', 'organisations']);

  const formatTypes = (typeList, num) => {
    const res = typeList.slice(0, num);
    let result = '';
    if (typeList.length > num) {
      result = `${res.map((gen) => ` ${gen.name}`)} ${t('applications:common.and')} ${typeList.length - num
        } ${t('applications:common.others')}`;
    } else {
      result = `${typeList.map((gen) => ` ${gen.name}`)}`;
    }

    return result;
  };

  const isDraft = is_draft || opp_status === OPP_STATUS_ENUM.DRAFT;

  const isClosed = opp_status === OPP_STATUS_ENUM.CLOSED || opp_status === OPP_STATUS_ENUM.ARCHIVED;

  const isScheduled = opp_status === OPP_STATUS_ENUM.SCHEDULED;

  const hasExpired = deadline_date && new Date(deadline_date) < new Date();

  const [anchorEl, setAnchorEl] = useState(null);

  const [deleteModal, setDeleteModal] = useState(false);
  const [isPremium, setIsPremium] = useState(false)

  const [oppDialog, setOppDialog] = useState(false);
  // const [selectedOpp, setSelectedOpp] = useState(null);

  const { data: oppsData } = useQuery(ALL_OPPORTUNITIES);

  const [createSpace, { loading: createSpaceMutationLoading, error: createSpaceMutationError, data: createSpaceMutationData }] = useMutation(CREATE_SPACE, {
    refetchQueries: () => [
      {
        query: MY_SPACES,
      },
    ],
  });

  const { data: premiumData, loading: premiumLoading } = useQuery(PREMIUM_USER_QUERY, {
    variables: {
      userId: userInfo?.id,
    },
  });

  const toggleOppsDialog = () => {
    setOppDialog(!oppDialog);
  };

  const handleDeleteDialogOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteDialogClose = () => {
    setAnchorEl(null);
    setDeleteModal(false);
  };

  const [
    deleteOpportunityMutation,
    { loading: deleteMutationLoading, error: deleteMutationError, data: deleteMutationData },
  ] = useMutation(DELETE_OPPORTUNITY, {
    onCompleted: () => {
      refetch();
      handleDeleteDialogClose();
    }
  });

  const [
    cloneOpportunity, 
    { loading: cloneOpportunityLoading, error: cloneOpportunityError, data: cloneOpportunityData },
  ] = useMutation(CLONE_OPPORTUNITY, {
    onCompleted: () => {
      refetch();
      setAnchorEl(null);
    }
  });

  const deleteOpportunityHandler = () => {
    deleteOpportunityMutation({
      variables: {
        id,
      },
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpportunityManage = () => {
    setAnchorEl(null);
    redirectToRoute({}, 'opportunity_dashboard', { params: { slug } });
  };

  const handleAddApplicant = () => {
    setAnchorEl(null);
    redirectToRoute({}, 'form_viewer', { params: { slug } });
  };

  const handleViewOpportunity = () => {
    setAnchorEl(null);
    redirectToRoute({}, 'view_opportunity', { params: { slug } });
  };

  // const handleUpdateForm = () => {
  //   setAnchorEl(null);
  //   redirectToRoute({}, 'update_form', { params: { slug } });
  // };

  const handleUpdateOpportunity = () => {
    setAnchorEl(null);
    redirectToRoute({}, 'update_opportunity', { params: { slug } });
  };

  // const handleUpdatePaymentInfo = () => {
  //   setAnchorEl(null);
  //   redirectToRoute({}, 'opportunity_payment', { params: { slug } });
  // };

  const handleOpportunityPayments = () => {
    setAnchorEl(null);
    redirectToRoute({}, 'opportunity_payments_details', { params: { slug } });
  };


  const handleCloneOpportunity = () => {
    cloneOpportunity({ variables: { slug } });
  }

  const handleDraftOpportunity = () => {
    setAnchorEl(null);
    redirectToRoute({}, 'draft_opportunity', { params: { slug } });
  };

  // const handleOpportunitySelection = (event, value) => {
  //   setSelectedOpp(value);
  // };
  
  const opportunity = oppsData?.allOpportunities?.find(opp => opp.id === id);

  const handleCreateSpace = () => {
    if (opportunity?.id) {
      createSpace({
        variables: {
          oppId: opportunity.id,
        },
        onCompleted: () => {
          toggleOppsDialog();
          redirectToRoute({}, 'spaces');
        },
      });
    }
  };

  const classes = useClasses();

  useEffect(() => {
    if (premiumData && !premiumLoading && premiumData?.premiumUser?.features[0] === "spaces") {
      setIsPremium(true)
    }
  }, [premiumData, premiumLoading]);


  return (
    <>
      <MutationsStatus 
        loading={cloneOpportunityLoading || deleteMutationLoading || createSpaceMutationLoading} 
        error={cloneOpportunityError || deleteMutationError || createSpaceMutationError} 
        success={cloneOpportunityData || deleteMutationData || createSpaceMutationData} 
      />
      <Card variant="elevation">
        <CardActionArea>
          {loading ? (
            <Skeleton animation="wave" variant="rect" height={200} />
          ) : (
            <div
              aria-hidden="true"
              className={classes.mediaContainer}
              onClick={isDraft ? handleDraftOpportunity : onClick}
              onKeyDown={isDraft ? handleDraftOpportunity : onClick}
            >
              <div className={classes.image}>
                <Image
                  // className={classes.image}
                  src={logo.path}
                  alt={title}
                  // layout="intrinsic" height="250px" width="443px"
                  onClick={isDraft ? handleDraftOpportunity : onClick}
                  layout="fill"
                  objectFit="cover"
                />
                {/* <CardMedia
                // component={Image}
                component="img"
                srcSet={responsiveSet}
                sizes="300px"
                // sizes={`${devices.xs} 300px, ${devices.sm} 400px, ${devices.md} 300px`}
                // sizes="50vw"
                src={logo.path}
                title={logo.name}
                onClick={onClick}
              /> */}
              </div>
              <div className={`${classes.additionalInfo} additionalInfo`}>
                <div className={classes.details}>
                  <OpportunityDescription variant="body1">
                    {summary || description}
                  </OpportunityDescription>
                  {/* {deadline_date && (
                    <Box display="flex" alignItems="center ">
                      <AccessAlarmIcon style={{ marginRight: '10px' }} />{' '}
                      <Typography weight="700" variant="overline">
                        {moment(deadline_date).format('DD MMM YYYY')}
                      </Typography>
                    </Box>
                  )} */}
                  {/* <StyledLink
                    href="organisation_view"
                    params={{ slug: organisation.slug }}
                    onClick={(event) => event.stopPropagation()}
                  >
                    <Typography variant="overline"> {organisation.name} </Typography>{' '}
                  </StyledLink> */}
                </div>
                <div className={classes.stats}>{is_paid && <MonetizationOnOutlinedIcon />}</div>
              </div>
              {/* </CardMedia> */}
            </div>
          )}
        </CardActionArea>

        <CardContent>
          {loading ? (
            <Box width="100%" pt={0.5}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
          ) : (
            <>
              <Link href="organisation_view" params={{ slug: organisation.slug }}>
                <OrganisationAvatar src={organisation.logo.thumb} elevation={2} width="64px" />
              </Link>
              <ContentContainer>
                <Typography color="primary" variant="overline">
                  <StyledLink
                    href="organisation_view"
                    params={{ slug: organisation.slug }}
                    onClick={(event) => event.stopPropagation()}
                  >
                    <Typography color="primary" variant="overline"> {organisation.name} </Typography>{' '}
                  </StyledLink>
                  {/* {start_date
                    ? (`${moment(start_date).format('DD MMM')} ${end_date ? `- ${moment(end_date).format('D MMM  YYYY')}` : ''
                    }`) : (
                      t('common:No Start Date Yet')
                    )} */}
                </Typography>
                <CardHeader
                  action={
                    userInfo &&
                    user &&
                    user.id === userInfo.id && (
                      <>
                        {isDraft && (
                          <Typography variant="h6" style={{ position: 'absolute', bottom: 30, right: 60 }}>
                            {t('common:DRAFT')}
                          </Typography>
                        )}
                        <IconButton
                          aria-label="settings"
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={handleClick}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </>
                    )
                  }
                  // title={<Link href="view_opportunity">{title}</Link>}
                  title={
                    title && title.length > 50 ? (
                      <Tooltip
                        title={title}
                        arrow
                        placement="top"
                        TransitionComponent={Zoom}
                        TransitionProps={{ timeout: 300 }}
                        // enterDelay={300}
                        leaveDelay={500}
                      >
                        <div>
                          <CardLabel
                            style={{ marginBottom: '10px' }}
                            variant="h5"
                            onClick={isDraft ? handleDraftOpportunity : onClick}
                          >
                            {/* `${title.substring(0, 51)}...` */}
                            {title}
                          </CardLabel>
                        </div>
                      </Tooltip>
                    ) : (
                      <CardLabel style={{ marginBottom: '10px' }} variant="h5" onClick={isDraft ? handleDraftOpportunity : onClick}>
                        {title && title}
                      </CardLabel>
                    )
                  }
                  subheader={
                    <>
                      {oppSearch && (
                        <MetaItem>
                          {isDraft ? <EditNoteIcon fontSize="small" /> : hasExpired ? <HourglassTopIcon fontSize="small" /> : isClosed ? <UnpublishedIcon fontSize="small" /> : isScheduled ? <ScheduleSendIcon fontSize="small" /> : <PublishedWithChangesIcon fontSize="small" />}
                          <Typography variant="caption">{ isDraft ? t('common:DRAFT') : hasExpired ? t('common:COMPLETED') : isClosed ? t('common:CLOSED') : isScheduled ? t('common:SCHEDULED') : t('common:ACTIVE') }</Typography>
                        </MetaItem>
                      )}
                      {(is_virtual || is_worldwide || (location && location.formatted_address)) ? (
                        <Tooltip
                          title={
                            is_worldwide
                              ? t('common:worldwide_opportunity')
                              : is_virtual
                                ? t('common:virtual_opportunity')
                                : location && location.formatted_address
                          }
                        >
                          <MetaItem>
                            {is_worldwide ?
                              <PublicIcon fontSize="small" />
                              :
                              is_virtual ? (
                                <LanguageIcon fontSize="small" />
                              ) : (
                                <RoomIcon fontSize="small" />
                              )}
                            <CardSublabel variant="caption" onClick={isDraft ? handleDraftOpportunity : onClick}>
                              {is_worldwide
                                ? t('common:worldwide_opportunity')
                                : is_virtual
                                  ? t('common:virtual_opportunity')
                                  : location && `${location.city}, ${location.country}`}
                            </CardSublabel>
                          </MetaItem>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title={t('common:location')}
                        >
                          <MetaItem>
                            <PublicIcon fontSize="small" />
                            <Typography variant="caption">
                              {t('common:No Selection')}
                            </Typography>
                          </MetaItem>
                        </Tooltip>
                      )}
                      {start_date ? (
                        <Tooltip
                          title={t('common:Start Date and End Date')}
                        >
                          <MetaItem>
                            <DateRangeIcon fontSize="small" />
                            <Typography variant="caption">{`${moment(start_date).format('DD MMM')} ${end_date ? `- ${moment(end_date).format('D MMM  YYYY')}` : ''}`}</Typography>
                          </MetaItem>
                        </Tooltip>
                      ) : (
                        <></>
                      )}
                      {types && types.length > 0 ? (
                        <Tooltip
                          title={t('common:Opportunity Categories')}
                        >
                          <MetaItem>
                            <LibraryMusicIcon fontSize="small" />
                            <Typography variant="caption">{formatTypes(types, 3)}</Typography>
                          </MetaItem>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title={t('common:Opportunity Categories')}
                        >
                          <MetaItem>
                            <LibraryMusicIcon fontSize="small" />
                            <Typography variant="caption">
                              {t('common:No Selection')}
                            </Typography>
                          </MetaItem>
                        </Tooltip>
                      )}
                      {deadline_date ? (
                        <Tooltip
                          title={t('common:Deadline Date')}
                        >
                          <MetaItem>
                            <AccessAlarmIcon fontSize="small" />
                            <Typography variant="caption">
                              {moment(deadline_date).format('DD MMM YYYY')}
                            </Typography>
                          </MetaItem>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title={t('common:Deadline Date')}
                        >
                          <MetaItem>
                            <AccessAlarmIcon fontSize="small" />
                            <Typography variant="caption">
                              {t('common:Ongoing')}
                            </Typography>
                          </MetaItem>
                        </Tooltip>
                      )}
                    </>
                  }
                />
              </ContentContainer>
            </>
          )}
        </CardContent>

      </Card>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={0}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {!isDraft && 
          <Menu.Item style={{ textTransform: 'capitalize' }} onClick={handleOpportunityManage}>
            {t('applications:manage_opp')}
          </Menu.Item>
        }
        {!isDraft && 
          <Menu.Item style={{ textTransform: 'capitalize' }} onClick={handleViewOpportunity}>
            {t('applications:view_opp')}
          </Menu.Item>
        }
        <Menu.Item style={{ textTransform: 'capitalize' }} onClick={handleUpdateOpportunity}>
          {t('applications:edit_opp_info')}
        </Menu.Item>
        {/* <Menu.Item style={{ textTransform: 'capitalize' }} onClick={handleUpdateForm}>
          {t('applications:update_form')}
        </Menu.Item>
        <Menu.Item style={{ textTransform: 'capitalize' }} onClick={handleUpdatePaymentInfo}>
          {t('applications:edit_payment')}
        </Menu.Item> */}
        {!isDraft && 
          <Menu.Item style={{ textTransform: 'capitalize' }} onClick={handleAddApplicant}>
            {t('applications:add_applicant')}
          </Menu.Item>
        }
        {!isDraft && 
          <Menu.Item style={{ textTransform: 'capitalize' }} onClick={handleOpportunityPayments}>
            {t('applications:view_paiments')}
          </Menu.Item>
        }
        {!isDraft && 
          <Menu.Item style={{ textTransform: 'capitalize' }} onClick={handleCloneOpportunity}>
            {t('applications:Duplicate Opportunity')}
          </Menu.Item>
        }
        {isDraft && 
          <Menu.Item style={{ textTransform: 'capitalize' }} onClick={handleDeleteDialogOpen}>
            {t('common:delete')}
          </Menu.Item>
        }
        {isPremium && !space && !isDraft && userInfo?.role?.name !== USER_ROLES.USER &&
          <Menu.Item style={{ textTransform: 'capitalize' }} onClick={toggleOppsDialog}>
            {t('common:Create Community')}
          </Menu.Item>
        }
      </Menu>

      <Dialog
        open={deleteModal}
        onClose={handleDeleteDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{`${t('common:delete')} ${t(
          'common:opportunity'
        )}`}</DialogTitle>
        <DialogContent>{t('applications:delete_opportunity_message')}</DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleDeleteDialogClose} color="primary">
            {t('common:cancel')}
          </Button>
          <Button onClick={deleteOpportunityHandler} color="primary">
            {t('common:delete')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog onClose={toggleOppsDialog} open={oppDialog} maxWidth='sm' fullWidth scroll="paper">
        <DialogTitle>Create Community Space</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={toggleOppsDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent>
          <Autocomplete
            id="opportunities-list"
            options={oppsData?.allOpportunities || []}
            getOptionLabel={(option) => (option && option.title) ? option.title : ''}
            disabled
            // onChange={handleOpportunitySelection}
            defaultValue={opportunity || null}
            value={opportunity || null}
            limitTags={5}
            filterSelectedOptions
            renderOption={(props, option) => (
              <Box
               component="li" 
               sx={{ '& > img': { mr: 2, flexShrink: 0 } }} 
               {...props}
              >
                <img
                  loading="lazy"
                  width="25"
                  height="25"
                  srcSet={option.logo?.path}
                  src={option.logo?.path}
                  alt="option.title"
                  style={{borderRadius: '5px'}}
                />
                {option.title}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Selected Opportunity"
                placeholder="Opportunites"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button loading={createSpaceMutationLoading} onClick={handleCreateSpace}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

OpportunityCard.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  userInfo: PropTypes.object,
  onClick: PropTypes.func,
  refetch: PropTypes.func,
  loading: PropTypes.bool,
  slug: PropTypes.string,
  location: PropTypes.object,
  logo: PropTypes.object,
  deadline_date: PropTypes.string,
  start_date: PropTypes.string,
  end_date: PropTypes.string,
  is_virtual: PropTypes.bool,
  is_worldwide: PropTypes.bool,
  types: PropTypes.array,
  user: PropTypes.object,
  organisation: PropTypes.object,
  description: PropTypes.string.isRequired,
  is_paid: PropTypes.bool.isRequired,
  summary: PropTypes.string.isRequired,
  is_draft: PropTypes.bool.isRequired,
  oppSearch: PropTypes.bool,
  opp_status: PropTypes.bool.isRequired,
  space: PropTypes.object,
};

OpportunityCard.defaultProps = {
  id: '',
  userInfo: undefined,
  loading: false,
  onClick: undefined,
  refetch: ()=> {},
  title: undefined,
  is_virtual: false,
  is_worldwide: false,
  slug: '',
  location: undefined,
  logo: undefined,
  space: undefined,
  deadline_date: '',
  start_date: '',
  end_date: '',
  types: [],
  user: undefined,
  oppSearch: false,
  organisation: undefined,
};

OpportunityCard.fragments = {
  opportunity: gql`
    fragment OpportunityCard on Opportunity {
      id
      title
      slug
      description
      summary
      location {
        formatted_address
        city
        country
      }
      logo {
        path
      }
      organisation {
        name
        slug
        logo {
          thumb
        }
      }
      is_paid
      space
      deadline_date
      start_date
      end_date
      is_virtual
      is_worldwide
      payment_options
      types {
        id
        name
      }
      user {
        id
        name
        avatar {
          path
        }
      }
    }
  `,
};

export default OpportunityCard;
