import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete as MuiAutocomplete } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '../../atoms/TextField';
import CitiesAutocomplete from './Cities';
import Image from '../../atoms/Image';

export const useClasses = makeStyles({
  LeftIconStyle: {
    borderRadius: '50%',
  },
});

const Autocomplete = ({
  id,
  options,
  variant,
  label,
  name,
  onChange,
  onBlur,
  getOptionLabel,
  type,
  leftIcon,
  defaultValue,
  inputProps,
  error,
  helperText,
  // margin,
  // inputAdornments,
  ...props
}) => {
  const classes = useClasses();

  if (type === 'cities') {
    return (
      <CitiesAutocomplete
        id={id}
        label={label}
        getOptionLabel={getOptionLabel}
        variant={variant}
        onChange={onChange}
        defaultValue={defaultValue}
        error={error}
        helperText={helperText}
        {...props}
      />
    );
  }
  return (
    <MuiAutocomplete
      id={id}
      // className={classes.}
      options={options}
      defaultValue={defaultValue}
      getOptionLabel={getOptionLabel}
      // style={{ width: 300 }}
      onChange={onChange}
      onBlur={onBlur}
      renderInput={(params) => {
        if (leftIcon) {
          // eslint-disable-next-line no-param-reassign
         
          // eslint-disable-next-line no-param-reassign
          params.InputProps.startAdornment = (
            <>
              <InputAdornment position="start">
                {typeof leftIcon === 'object' ? (
                  leftIcon
                ) : (
                  <Image
                    width="24px"
                    height="24px"
                    src={leftIcon}
                    alt="logo"
                    className={classes.LeftIconStyle}
                  />
                )}
              </InputAdornment>
              {params.InputProps.startAdornment}
            </>
          );
        }
        return (
          <TextField
            {...inputProps}
            error={error}
            name={name}
            helperText={helperText}
            onBlur={onBlur}
            {...params}
            label={label}
            variant={variant}
            fullWidth
            autoComplete="off"
           
          />
        );
      }}
      // autoComplete={false}
      {...props}
    />
  );
};

Autocomplete.propTypes = {
  id: PropTypes.string,
  getOptionLabel: PropTypes.func,
  onChange: PropTypes.func,
  type: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array,
  label: PropTypes.string,
  variant: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  leftIcon: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  defaultValue: PropTypes.object
};
Autocomplete.defaultProps = {
  getOptionLabel: (option) => option && option.name,
  onChange: () => {},
  type: null,
  id: null,
  options: [],
  label: '',
  variant: undefined,
  error: false,
  helperText: undefined,
  leftIcon: null,
  name: '',
 onBlur: () => {},
 defaultValue: {},
};

export default Autocomplete;
